// eslint-disable-next-line no-redeclare,no-unused-vars
function sorter(param) {
  var fields = param.fields;
  var list = param.list;
  var sub = param.list2;
  var view;
  var treeCont;

  // build list
  var buildList = function (cont, list) {
    var ul = $('<ul></ul>').appendTo(cont);
    if (!isArray(list)) list = list.split(',');
    $.each(list, function (i, key) {
      var settings = fields[key];
      if (!settings) return;
      var label = settings.label;
      let labelCont;
      if (test && user.admin) label += ' (' + key + ')';
      if (sub && sub[key]) {
        var li = $('<li key="' + key + '"></li>').appendTo(ul);
        labelCont = $('<a class="border p-3 my-2">' + label + '</a>').appendTo(
          li
        );
        buildList(li, sub[key]);
      } else
        labelCont = $(
          '<li class="border p-3 my-2" key="' + key + '">' + label + '</li>'
        ).appendTo(ul);
      if (sub) addLevelBtn(labelCont);
    });
  };

  // add level button
  var addLevelBtn = function (cont) {
    $(
      '<span class="float-end px-2 link"><i class="icon fa-regular fa-angle-right"></span>'
    )
      .appendTo(cont)
      .on(click, function () {
        var li = $(this).parents('li:first');
        var prev = li.prev();
        if (!prev[0]) return;

        if (prev.hasClass('treeview-category')) {
          var a = $(
            '<a class="border p-3 my-2 treeview-category">' +
              prev.text() +
              '</a>'
          );
          prev.children('span').appendTo(a);
          prev
            .empty()
            .removeClass('border p-3 my-2 treeview-category')
            .append(a);
          var ul = $('<ul class="treeview-line collapse show"></ul>').appendTo(
            prev
          );
          li.appendTo(ul);
        } else li.appendTo(prev.children('ul'));

        li.find('li')
          .addBack()
          .each(function (i, li) {
            $(li).attr('aria-level', parseInt($(li).attr('aria-level')) + 1);
          });
      });
  };

  // save list
  var saveList = function () {
    var values = [];
    var subs = {};
    var subs2 = {};

    treeCont.find('li').each(function (i, li) {
      var key = $(li).attr('key');
      var level = parseInt($(li).attr('aria-level'));
      if (level == 1) values.push(key);
      else if (level == 2) {
        const top = $(li).parents('[aria-level]:first').attr('key');
        if (!subs[top]) subs[top] = [];
        subs[top].push(key);
      } else if (level == 3) {
        const top = $(li).parents('[aria-level]:first').attr('key');
        if (!subs2[top]) subs2[top] = [];
        subs2[top].push(key);
      }
    });

    if (sub) values = {top: values, subs: subs, subs2: subs2};
    param.save(values);
    view.close();
  };

  // build structure
  param.buttons = {};
  param.buttons[info.title.choose] = function () {
    saveList();
  };
  view = buildPopup(param);
  var contCard = $('<div class="card mh-100"></div>').appendTo(view.body);
  var card = $('<div class="card-body d-flex"></div>').appendTo(contCard);
  view.addClass('modal-dialog-no-scrollable');
  setTimeout(function () {
    view.body.height(view.body.height());
  }, 500);

  // add fields tree
  treeCont = $(
    '<div class="treeview flex-fill scrollable" data-mdb-open-on-click="false"></div>'
  ).appendTo(card);
  buildList(treeCont, list);
  new window.Treeview(treeCont[0]);

  // all fields
  var allCont = $('<ul class="flex-fill scrollable"></ul>').appendTo(card);
  $.each(fields, function (key, settings) {
    if (
      treeCont.find('li[key=' + key.replace('.', '\\.') + ']')[0] ||
      !settings.label
    )
      return;
    var label = settings.label;
    if (test && user.admin) label += ' (' + key + ')';
    else if (!label) return;
    $(
      '<li class="list-group-item border rounded p-3 my-2" key="' +
        key +
        '">' +
        label +
        '</li>'
    ).appendTo(allCont);
  });

  // add sortable
  var pm = {
    cursor: 'move',
    items: 'li',
    connectWith: allCont,
    forceHelperSize: true,
    update: function (evt, ui) {
      var level = 1;
      if (ui.item.parent('ul.collapse')[0])
        level = parseInt(ui.item.parents('li:first').attr('aria-level')) + 1;
      ui.item.attr('aria-level', level);
      if (
        !ui.item.hasClass('treeview-category') &&
        ui.item.parents('.treeview')
      ) {
        ui.item.addClass('treeview-category');
        if (sub) addLevelBtn(ui.item);
      }
    },
    // sort: function (evt, ui) {
    // 	var el = document.elementFromPoint(ui.offset.left, ui.offset.top);
    // 	if (el && $(el).is('li') && $(el).hasClass('treeview-category')) {
    // 		focused = el;
    // 		setTimeout(function () {
    // 			if (focused == el && $(el).hasClass('treeview-category')) {
    // 				var label = $(el).text();
    // 				$(el)
    // 					.html('<a class="border p-3 treeview-category">' + label + '</a><ul class="treeview-line collapse show"><li class="helper p-3">...</li></ul>')
    // 					.removeClass('border p-3 my-2 treeview-category');
    // 			}
    // 		}, 3000);
    // 	}
    // }
  };
  treeCont.sortable(pm);
  pm.connectWith = treeCont;
  allCont.sortable(pm);

  // expand all
  treeCont.find('[data-mdb-toggle]').trigger(click);
}
